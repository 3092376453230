<template>
	<HelloWorld msg="Rozcestník Bohemia privat" />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  created(){
    document.title = "Vinařství Laurent Šatov"
  }
}
</script>

<style>

body{
	margin: 0;
}

body, html { 
    overflow-x: hidden; 
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 0px;
}
</style>
