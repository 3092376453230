<template>
	<transition name="fade">
		<img v-if="hover == 'ubytovani'" class="background" src="@/assets/pozadi_ubytovani.jpg" />
	</transition>
	<transition name="fade">
		<img v-if="hover == 'vinarstvi'" class="background" src="@/assets/pozadi_vinarstvi.jpg" />
	</transition>
	<transition name="fade">
		<img v-if="hover == 'eshop'" class="background" src="@/assets/pozadi_eshop.jpg" />
	</transition>
	<transition name="fade">
		<img v-if="hover == 'masaze'" class="background" src="@/assets/pozadi_masaze.jpg" />
	</transition>
	
	<div id="rozcestnik">
		<div @mouseover="changeHover('ubytovani')" class="box ubytovani">
			<a href="https://www.laurentsatov.cz">
				<img src="@/assets/rozcestnik01.jpg" />
				<span>Ubytování</span>
			</a>
		</div>
		
		<div @mouseover="changeHover('vinarstvi')" class="box sklep">
			<a href="https://www.laurentsatov.cz/vinarstvi/">
				<img src="@/assets/rozcestnik02.jpg" />
				<span>Vinařství</span> 
			</a>
		</div>
		
		<div @mouseover="changeHover('eshop')" class="box vino">
			<a href="https://eshop.laurentsatov.cz"><img src="@/assets/rozcestnik03.jpg" /></a>
			<span>Eshop</span>
		</div>
		
		<div @mouseover="changeHover('masaze')" class="box masaze">
			<a href="https://www.laurentsatov.cz/wellness/"><img src="@/assets/rozcestnik04.jpg" /></a>
			<span>Wellness</span>
		</div>
		
		<div v-if="hover == 'ubytovani'" class="box info">
			<p><strong>Nabízíme kvalitní ubytování v rodinném prostředí s domácí kuchyní a vinným sklípkem za lidové ceny.</strong></p>
			<p>Ta pravá zasloužená dovolená na Jižní Moravě, v Šatově, v malebné krajině národního parku Podyjí.</p>
			<p>Jsme nejen ideální pro klidnou a pohodovou rodinnou dovolenou v příjemném prostředí, ale také pro milovníky dobrého vína, vinařské turistiky, cykloturistiky, gastronomických specialit a folklóru.</p>
			<p>Pouze 5 minut do Rakouska, 40 min do Vídně a 5 min do národního parku Podyjí.</p>
			<ul>
				<li><strong>Celková kapacita 61 lůžek – 23 pokojů a apartmánů</strong></li>
				<li><strong>Vyberte si z 2-4 lůžkových pokojů a apartmánů.</strong></li>
			</ul>
			<a href="https://www.laurentsatov.cz">https://www.laurentsatov.cz</a>
		</div>

		<div v-if="hover == 'vinarstvi'" class="box info">
			<h2>Vinařství</h2>
			<p>Naše rodinné vinařství založené v roce 2012. Výrobou vína jsme se zabývali již mnoho let předtím, ale byl to pro nás především koníček. Objem vyráběného vína však každoročně stoupal, společně s kvalitou. 
			Již po pár letech jsme získali hezká ocenění vítězů v odrůdě nebo zlaté medaile na vinařských výstavách. Snažíme se tedy tento trend zachovat.</p>
			<h2>Degustace</h2>
			<p>Objednejte si ochutnávku vín a vyberte si ze dvou degustačních sklepů s kapacitou až 45 osob. Nachystáme pro Vás vynikající vzorky, pohoštění i živou hudbu.</p>
			<a href="https://www.laurentsatov.cz/vinarstvi/">https://www.laurentsatov.cz/vinarstvi</a>
		</div>
		
		<div v-if="hover == 'eshop'" class="box info">
			<h2>Eshop</h2>
			<p>Každoročně vyrábíme alespoň 17 odrůd vín, která jsou ručně sbírána do 10km od našeho vinařství. Všechna vína jsou tedy z vinařské oblasti Znojmo. Naše vína můžou být zakoupena na e-shopu, kde od nákupu vín Vám <strong>do 3 dnů doručíme vína po celé ČR</strong>. Při nákupu nad 5 krabic nyní z dopravou zdarma. Využijte i naší limitované edice a degustačních sad vín.</p>
			<a href="https://eshop.laurentsatov.cz">https://eshop.laurentsatov.cz</a>
		</div>
		
		<div v-if="hover == 'masaze'" class="box info">
			<h2>Wellness</h2>
			<p>Nabízíme Vám relaxační a sportovní masáže od fyzioterapeuta a profesionála ve sportovní rehabilitaci s více než 10 letou odbornou praxí u nás v dětské léčebně Vesna v Janských Lázních pro zdravotně postižené děti,  tak  i v zahraničí  v hotel & spa rezortech na Kypru nebo v Anglické Premier league fotbatbalového klubu Burnley.</p>
			<p>Vybrat si můžete od relaxační a sportovní masáže, přes fyzioterapii a sportovní rehabilitaci až profesionální poradenství.</p>
			<a href="https://www.laurentsatov.cz/wellness/">https://www.laurentsatov.cz/wellness/</a>
		</div>
		
		<div v-if="hover == 'rezervace'" class="box info">
			<p>Rezervujte si ubytování. Dopřejte si zážitek z moravského sklípku, ochutnejte výbornou domácí kuchyni a objevujte okolí s vinohrady a krásy národního parku Podyjí pěšky i na kole.</p>
			<p>Postaráme se o vás stejně dobře jako o bobule vína.</p>
			<a href="https://www.laurentsatov.cz/rezervace/">https://www.laurentsatov.cz/rezervace/</a>
		</div>
		
		
	</div>
	
	<div class="bottom-info">
		<img src="@/assets/logo.jpg" />
		<h1>Rodinné ubytování Privát Bohemia a Rodinné vinařství Laurent Šatov</h1>
		<p><strong>Vítejte na nových stránkách penzionu Privát Bohemia a rodinného vinařství Laurent Šatov.</strong></p> 
		<p>Vždy se jednalo o ubytování a vinařství vedené naší rodinou, proto jsme se rozhodli názvy sjednotit a již zažité ubytování Privát Bohemia také přejmenovat na Vinařství Laurent Šatov.</p>
		<br />
		<p>Prosím vyberte si v rozcestníku službu, o kterou máte zájem ...</p>
	</div>
</template>

<script>
export default {
  name: 'BohemiaPrivat',
  props: {
    msg: String
  },
  data() {
    return {
      hover: 'ubytovani'
    }
  },
  methods: {
    changeHover: function(newHover){
      this.hover = newHover;
    }
  },
  watch: {
    //hover: function(value) {
      //document.body.style.backgroundImage = 'url("/pozadi_' + value + '.jpg")';
      //document.body.style.backgroundImage = 'url("/pozadi_' + value + '.jpg")';
      //document.body.style.backgroundImage = 'url("' + require('../assets/pozadi_' +  value + '.jpg') + '")';
    //}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media (max-width: 420px) {
	#rozcestnik .box{
		width: 100%;
	}	
}

@media (max-width: 767px) {
	.rozcestnik,
	.bottom-info{
		font-size: 16px;
	}

	.background,
	.bottom-info img{
		display: none;
	}

	#rozcestnik{
		text-align: center;
	}

	#rozcestnik .box{
		width: 45%;
		min-width: 202px;
		margin: 20px 0 0 0;
	}	
	
	#rozcestnik .box.info{
		width: initial;
		padding: 40px;
	}
	
	.box span{
		text-decoration: underline;
	}
}

@media (min-width: 768px) {
	#rozcestnik {
		width: 1218px;
		height: 400px;
		margin: 0 auto;
		margin-top: 60px;
		position: relative;
	}

	#rozcestnik .box{
		float: left;
	}
	
	.bottom-info{
		width: 1100px;
	}
	
	#rozcestnik .info {
		background-repeat: no-repeat;
		width: 370px;
		height: 360px;
		padding: 20px;
		font-size: 14px;
	}
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
}

#rozcestnik .box {
	display: inline-block;
	position: relative;
}

#rozcestnik .box span{
	position: absolute;
    width: 100%;
    bottom: 30px;
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
	z-index: 10;
	left: 0;
}

#rozcestnik .info {
	background-repeat: no-repeat;
	color: white;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 20px;
	text-align: left;
    background: rgb(27 31 76 / 57%);
}

#rozcestnik .info a {
	color: white;
}

.bottom-info{
	background: rgba(255, 255, 255, 0.8);
	padding: 50px;
	margin: 50px auto;
	clear: both;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
